<template>
    <div>
      <AlertTable
        :canEdit="true"
        editRoute="SuperAdminClinicEdit"
        createRoute="SuperAdminClinicCreate"
      />
      <!-- <clinicList
        :canEdit="true"
        editRoute="SuperAdminClinicEdit"
        :canCreate="true"
        createRoute="SuperAdminClinicCreate"
        :canViewDetail="true"
        viewRoute="SuperAdminClinicDetail"
        :canViewClinicTreatmentHistory="true"
        clinicTreatmentHistoryRoute="SuperAdminClinicTreatmentHistory"
      /> -->
    </div>
  </template>
  
<script>
import AlertTable from '../../components/alerts/alertTable.vue';

export default {
  components: {
    AlertTable,
  },
  data: () => ({}),
};
</script>
  